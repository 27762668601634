import * as React from "react";
import { Helmet } from "react-helmet";

import { useConfig } from "gatsby-theme-advanced";

import Layout from "../layouts";
import './css/styles.css'

const AboutPage = (): JSX.Element => {
  const config = useConfig();

  return (
    <Layout>
      <div className="404 centered-container">
        <Helmet title={`404 | ${config.website.title}`} />
        <h1>404</h1>
        <p>Page cannot be found</p>
      </div>
    </Layout>
  );
};

export default AboutPage;
